import { useEffect, useState } from 'react'
import { getProfileDefaultLanguage } from 'api/CommonApi'
import { useSessionStorage } from 'usehooks-ts'
import { FALSE, IS_LOGGING_IN, TRUE } from 'model/StorageConstants'
import { defaultRegions, SupportedLanguage, supportedLanguageTags } from 'utils/supportedLanguages'

const isLanguageTagSupported = (
  /* eslint-disable-next-line */
  language: any
): boolean => {
  return supportedLanguageTags.includes(toLanguageTag(language))
}

/* eslint-disable-next-line */
export const getTranslationForLanguage = async (languageTag: string): Promise<any> => {
  try {
    return await import(`locales/${languageTag}.json`)
  } catch (error) {
    const language = languageTag.substring(0, 2)
    return await import(`locales/${defaultRegions.get(language)}.json`)
  }
}
export const useLanguage = (): SupportedLanguage => {
  const language = getLanguageFromUrl()
  const [isLoggingIn] = useSessionStorage(IS_LOGGING_IN, FALSE)
  const [isLoadingProfileError, setIsLoadingProfileError] = useState(false)

  useEffect(() => {
    if (isLoggingIn === TRUE || isLanguageTagSupported(language)) {
      return
    }
    if (defaultRegions.has(language)) {
      // @ts-ignore
      redirect(defaultRegions.get(language))
      return
    }
    if (!isLanguageTagSupported(language) && !isLoadingProfileError) {
      getProfileDefaultLanguage()
        .then(({ value }) =>
          value && isLanguageTagSupported(value) ? redirect(value) : setIsLoadingProfileError(true)
        )
        .catch(() => {
          setIsLoadingProfileError(true)
        })
      return
    }
    const browserLanguage = getNavigatorLanguage()
    isLanguageTagSupported(browserLanguage) ? redirect(browserLanguage) : redirect('en-us')
  }, [language, isLoggingIn, isLoadingProfileError])

  return language as SupportedLanguage
}

const getLanguageFromUrl = (): string => {
  const path = window.location.pathname
  const langMatch = path.match('[^/]([^/]+)')
  if (langMatch?.length && (langMatch[0].length === 2 || langMatch[0].length === 5)) {
    return langMatch[0].replace(/\//g, '').replace('_', '-') as SupportedLanguage
  }
  return ''
}

const redirect = (languageTag: string): void => {
  const formattedLanguage = `${languageTag.substring(0, 2)}-${languageTag.substring(3).toUpperCase()}`

  const suffix = getUrlSuffix()

  window.history.pushState(
    formattedLanguage,
    '',
    `/${formattedLanguage}${suffix !== '/' ? suffix : ''}${window.location.search}`
  )
  window.location.reload()
}

const getUrlSuffix = (): string => {
  const path = window.location.pathname
  const languageFromUrl = getLanguageFromUrl()
  const suffixStart = languageFromUrl ? languageFromUrl.length + 1 : path.indexOf('/', 0)
  return path.substring(suffixStart)
}

const getNavigatorLanguage = (): string => {
  return defaultRegions.get(navigator.language) || navigator.language
}

/* eslint-disable-next-line */
const toLanguageTag = (language: string): any => {
  return language.substring(0, 3) + language.substring(3, 5).toUpperCase()
}
